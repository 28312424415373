$marginLeftFromText: 10px;

.headerPathContainer {
  margin-left: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  .logoContainer {
    width: 42px;
    height: 27px;
    .logo {
      width: 42px;
      height: 27px;
    }
  }
  .pathComponentContainer {
    margin-left: 11px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: fit-content;

    .pathArrow {
      height: 19px;
      width: 11px;
    }
    .finalPathComponent,
    .scenarioPathComponent,
    .notFinalPathComponent {
      position: relative;
      margin-left: $marginLeftFromText;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      .pathTitle {
        .hiddenPlaceholder {
          font-size: 16px;
          font-weight: $boldWeight;
          visibility: hidden;
          height: 0px;
        }
        .word-limit {
          display: flex;
          box-sizing: border-box;
          max-width: 15vw;

          :first-child {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: block;
            box-sizing: border-box;
          }

          span:last-child {
            white-space: nowrap;
          }
        }
        .pathTitleText {
          color: $ageanMidnight;
          font-size: 16px;
          white-space: nowrap;
        }
        .final {
          color: $popCulture;
          font-weight: $boldWeight;
          span {
            color: $popCulture;
            font-weight: $boldWeight;
          }
        }
        .link {
          &:hover {
            color: $ageanMidnight;
            font-weight: $boldWeight;
            cursor: pointer;
          }
        }
      }
      .scenariosArrow {
        width: 19px;
        height: 11px;
        margin-top: 2px;
        margin-left: 5px;
        cursor: pointer;
      }

      .up {
        transform: rotate(180deg);
      }
      .scenarioSubMenuContainer {
        background-color: white;
        margin-left: -5px;
        min-width: 250px;
        max-width: 430px;
        width: calc(100% + 10px);
        position: absolute;
        top: 31px;
        box-shadow: 0px 6px 6px #00000029;
        border-radius: 0px 0px $borderRadiusSmall $borderRadiusSmall;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        max-height: 220px;
        overflow-y: scroll !important;
        overflow-x: hidden;
        .scenarioNameContainer {
          width: 100%;
          .scenarioName {
            margin-top: 5px;
            margin-left: 10px;
            min-height: 25px;
            width: 90%;
            text-align: left;
            color: $ageanMidnight;
            font-size: 16px;
            font-weight: $boldWeight;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            cursor: pointer;
            &:hover {
              color: $popCulture;
            }
          }
        }

        &::-webkit-scrollbar {
          -webkit-appearance: none;
          width: 6px;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 5px;
          background-color: rgba(0, 0, 0, 0.5);
          box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
        }
      }
      .wordLimit-cut {
        display: inline-flex;
        box-sizing: border-box;
        max-width: 15vw;

        transition: 0.1s width;
        :first-child {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          display: block;
          box-sizing: border-box;
        }

        span:last-child {
          white-space: nowrap;
        }
      }
    }
  }
}
