.tool-component {
  .firstProceedingsImg {
    width: 24px;
    position: relative;
    margin-left: 10px;
  }
  .secondProceedingsImg {
    margin-left: 10px;
    width: 55px;
  }
  .downloadImageComponentContainer {
    position: absolute;
    right: 0px;
    top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .mnmMessageContainer {
    position: absolute;
    color: $greekEaster;
    left: 500px;
    top: 20px;
    font-size: 20px;
  }
  .asteriskMessageContainer {
    margin-left: 47px;
    margin-top: 10px;
    color: $grayForReport;
    &.newExecutiveSummary {
      margin-top: 0px;
      padding-left: 0px;
      color: $ageanMidnight;
      .robotoNumbers {
        color: $ageanMidnight;
      }
    }
    &.previewPDF {
      margin-left: 110px;
    }
    span {
      color: $grayForReport;
    }
    .asteriskMessageContainer-learnMoreSpan {
      color: $popCulture;
      text-decoration: underline;
      cursor: pointer;
      font-weight: 400;
      &:hover {
        font-weight: 600;
      }
    }
  }
  #settlement-asteriskMessageContainer {
    margin-top: 30px;
  }
  #graph-asteriskMessageContainer {
    margin-top: 30px;
    &.distribution {
      margin-top: 150px;
    }
  }
  &#eReportComp {
    margin-top: 20px;
  }

  position: relative;
  background-color: white;
  border-radius: $borderRadiusLarge;
  box-shadow: 0px 5px 55px $shadowColor;
  float: left;
  @media screen and (max-width: 1500px) {
    width: 95% !important;
  }

  @media screen and (max-height: 900px) {
    &:last-of-type {
      margin-bottom: 100px;
    }
  }
  @media only screen and (max-width: 836px) {
    max-width: $toolComponentMinWidth !important;
  }
  width: 100%;
  max-width: $toolComponentMaxWidth;
  min-width: $toolComponentMinWidth;
  box-sizing: border-box;
  padding-bottom: 37px;
  margin-top: 55px;

  &.for-settings {
    margin-bottom: 200px;
  }
  &.for-users {
    height: calc(90vh - 150px);
    min-height: 440px;
  }
  #graphTitle-expectedValueBreakdown,
  #executiveSummary-title {
    user-select: auto !important;
    cursor: text;
  }
  .tool-component-title {
    &.graphTitle {
      user-select: auto !important;
      cursor: text;
    }
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-top: 16px;
    margin-left: 19px;
    margin-bottom: 0px;
    padding: 0px;
    font-size: 26px;
    font-family: 'poppins-local-bold';
    white-space: nowrap;
    margin-right: 50px;
    background-color: transparent;

    img {
      margin-left: 14px;
      margin-right: 14px;
      margin-bottom: 3px;
    }
    .claimTitle {
      font-size: 26px;
      font-weight: $boldWeight;
    }

    .titleClaimName {
      font-size: 20px;
      font-weight: 400;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      margin-bottom: -5px;
    }
  }

  .tool-component-second-title {
    font-size: 20px;
    margin-top: 37px;
    width: fit-content;
    font-weight: calc(#{$boldWeight} - 100);
    &.for-settings {
      font-weight: calc(#{$boldWeight} + 100);
    }
  }

  .tool-component-third-title {
    margin-top: 37px;
  }

  .tool-component-spacer {
    visibility: hidden;
  }

  .tool-component-subtitle {
    margin-top: 22px;
    font-size: 16px;
    margin-right: 20px;
    max-width: 830px;
  }

  .formatDropdownOuterContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    .formatDropdownSaveButtonContainer {
      margin-top: 43px;
    }
  }

  .tool-component-input-container {
    //margin-top: 30px; // We want a final 43px, but I am counting the 13px of the Helper Text heigth also, that it is always there
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    img {
      margin-right: 30px;
    }

    margin-top: 10px;
    &.first-container {
      margin-top: 43px;
    }

    .claimTitle {
      margin-bottom: -38px;
    }
  }

  .tool-component-add-button-container {
    position: relative;
    margin-top: 48px; // We want a final 48px, but I am counting the 13px of the Helper Text heigth also, that it is always there
    &.subtitleTickboxContainer {
      margin-top: 15px; // We want a final 48px, but I am counting the 13px of the Helper Text heigth also, that it is always there
    }
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    width: fit-content;

    .addSignContainer {
      position: relative;
      cursor: pointer;
      img {
        width: 21px;
        margin-right: 12px;
        cursor: pointer;
      }
    }
    p {
      font-size: 16px;
      cursor: pointer;

      &.disabled {
        cursor: default;
      }
    }

    .tickImg {
      width: 36px;
      margin-right: 12px;
      cursor: pointer;

      &.disabled {
        cursor: default;
      }
    }
  }

  .opposingPartySymbolImg {
    margin-left: 8px;
    margin-top: -1px;
  }

  .tool-component-submenu-container {
    position: relative;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    padding-bottom: 35px; // We want a final 42px, but I am counting the 13px of the Helper Text heigth also, that it is always there
    margin-top: 38px;
    margin-left: 78px;
    margin-right: 20px;
    .tool-component-remove-button-container {
      margin-left: 17px;
      margin-top: 17px; // We want a final 48px, but I am counting the 13px of the Helper Text heigth also, that it is always there
      margin-bottom: 12px;
      display: flex;
      justify-content: flex-start;
      flex-direction: row;
      align-items: center;
      width: fit-content;

      .addSignContainer {
        position: relative;
        cursor: pointer;
        img {
          width: 21px;
          margin-right: 12px;
          cursor: pointer;
        }
      }
      p {
        font-size: 16px;
        cursor: pointer;
      }
    }
    .tool-component-submenu-input-container {
      margin-left: 22px;
      margin-top: 35px; // We want a final 43px, but I am counting the 13px of the Helper Text heigth also, that it is always there
      &:first-of-type {
        margin-top: 47px;
      }

      img {
        margin-right: 30px;
      }
      height: 60px;
      &.bigger {
        height: 90px;
      }
      display: flex;
      justify-content: flex-start;
      flex-direction: row;
      align-items: center;
    }
    .secondProbContainer {
      display: flex;
      justify-content: flex-start;
      flex-direction: row;
      align-items: center;
      position: absolute;
      top: 130px;
      left: 361.5px;

      .tool-component-submenu-text {
        margin-top: 19px; // We want a final 32px, but I am counting the 13px of the Helper Text heigth also, that it is always there
        margin-left: 4px;
        margin-bottom: 13px; //This is instead of the height of the input-helper-text
        margin-right: 13px; //This is instead of the height of the input-helper-text
        @media screen and (max-width: 900px) {
          font-size: 15px;
        }
        span {
          font-weight: $boldWeight;
        }
      }
    }
  }
  .tool-component-switch-container {
    margin-top: 50px;
  }

  .addSecondTrialContainer {
    width: fit-content;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;

    p {
      cursor: pointer;

      font-size: 12px;
      font-weight: $boldWeight;
    }
  }

  h6 {
    position: absolute;
  }

  .errorMsg3 {
    position: absolute;
    width: 320px;
    top: -20px;

    color: #eb3030;
  }
  .errorMessagesContainer {
    height: 20px;
    position: relative;
    .firstError {
      left: 35px;
    }
    .secondErrror {
      left: 295px;
    }
  }
}

.results-tool-component {
  margin-top: 40px;
  @media screen and (max-width: 1300px) {
    width: 90% !important;
    margin-left: 42px;
  }

  .graphSubtitle {
    margin-left: 18px;
    color: $grayForReport;
    margin-right: 40px;
    user-select: auto !important;
    cursor: text;
    span {
      color: $grayForReport;
    }
  }
  .oldNewSwitch {
    margin-left: 3px;
    cursor: pointer;
    .oldNewSwitchText {
      cursor: pointer;
      user-select: none !important;
      color: $popCulture;
      text-decoration: underline;
      font-size: 16px;
    }
  }
}
.results-tool-component-first {
  margin-top: 20px;
}

.marginLeft1 {
  margin-left: 50px;
}
.marginLeft2 {
  margin-left: 95px;
}
.marginLeft3 {
  margin-left: 100px;
}
.marginLeft4 {
  margin-left: 73px;
  margin-bottom: -26px;
}
.marginLeft5 {
  margin-left: 73px;
  margin-bottom: -26px;
}
.marginLeft6 {
  margin-left: 85px;
}
.marginLeft7 {
  margin-left: 100px;
  margin-bottom: -26px;
  margin-top: 15px !important;
}
