.legalFeesDistributionRadioButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 200px;
  z-index: 1px;
  margin-left: 55px;
  .legalFeesDistributionMethod {
    margin-bottom: 13px;
  }
}

.legalFeesDistributionAnalysisButton {
  margin-left: 55px;
  margin-top: 50px;
  background-color: $popCulture;
  padding: 1px 42px 1px 42px;
  height: 45px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: $borderRadiusSmall;
  cursor: pointer;
  &:hover {
    background-color: $popCultureHover;
    cursor: pointer;
  }
  .legalFeesDistributionAnalysisButtonText {
    color: white;
    font-size: 18px;
    font-weight: $boldWeight;
    white-space: nowrap;
    cursor: pointer;
    &.disabled {
      cursor: auto;
    }
  }
  &:disabled {
    background-color: #818181 !important;
    cursor: auto;
  }
}

.legalFeesDistributionDetailsText {
  margin: 40px 0px 20px 55px;
}

.legalFeesDistributionDetails {
  margin-top: 62px;
  margin-bottom: 50px;
  box-sizing: unset;
  max-width: 1280px;
  .slider {
    border: 0;
  }
}
.noLegalCostsIncludedBackground {
  background-color: white;
  position: absolute;
  width: 100%;
  margin-top: 10px;
  height: calc(100% - 70px);
  top: 45px;
  z-index: 2;
}
.noLegalCostsIncludedContainer {
  position: absolute;
  background-color: transparent;
  width: 100%;
  height: 80%;
  top: 15%;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  .noLegalCostsIncludedMessageContainer {
    width: 674px;
    height: 85px;
    background-color: white;
    border-radius: 13px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 75px 0 75px;
    margin-top: 120px;
    margin-bottom: 10px;
    .noLegalCostsIncludedMessage {
      font-size: 20px;
      font-weight: 900;
      font-family: 'poppins-local-bold';
      line-height: 1.25;
      text-align: center;
    }
  }
}
.legalFeesDistributionTransparentLayer {
  position: absolute;
  background-color: transparent;
  width: 100%;
  height: 80%;
  z-index: 3;
}

.legalFeesDistributionTransparentLayer {
  position: absolute;
  background-color: transparent;
  width: 100%;
  height: 80%;
  z-index: 3;
}

#legalFeesDistributionComp {
  margin-bottom: 200px;
  position: relative;
}

.lcdChipsContainer {
  max-width: 750px;
  min-height: 67px;
  min-width: 510px;
  box-shadow: 0px 3px 14px #00000029;
  border-radius: 34px;
  margin-left: 40px;
  margin-right: 40px;
  margin-top: 26px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 5px 0 5px;
  .lcdChip {
    margin: 10px 10px 10px 10px;
    padding: 10px 15px 10px 15px;
    min-height: 45px;
    min-width: 100px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    .lcdChipText {
      &.notViewer {
        cursor: pointer;
      }
      line-height: 1.1;
      text-align: center;
    }
    &.selected {
      background-color: $springLevanter;
      border-radius: 23px;
      cursor: pointer;
    }

    &:hover {
      cursor: default;

      &.notViewer {
        cursor: pointer;
      }
    }
  }
}

.LCDStandardText,
.LCDEachPartyPaysText {
  margin: 22px 50px 30px 50px;
  max-width: 830px;
  span {
    cursor: pointer;
    user-select: none !important;
    color: $popCulture;
    text-decoration: underline;
  }
}
