@import './smallComponents/dateOfTrialComponent';
@import './smallComponents/secondTrialProbsComponent';
@import './smallComponents/legalCostsPreviewComponent';
@import './previewSectionsComponents/reportParagraph';
@import './previewSectionsComponents/reportSection';

.previewPdfContainer {
  padding: 77px $reportPaddingLeft 77px 0px;
  .mnmMessageContainer {
    left: 40px;
    top: 20px;
  }
  .caseDetailsContainer {
    position: relative;
    margin-left: $reportPaddingLeft;
    min-height: 160px;
    margin-bottom: 52px;
    &.eversheds {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-end;
      min-height: 260px;
    }
    .caseNameTitle {
      font-size: 36px;
      font-weight: $boldWeight;
      max-width: 811px;
    }
    .scenarioNameTitle {
      max-width: 790px;
    }

    .caseNameSubtitle {
      font-weight: 300;
      font-style: italic;
    }
    .eperotoLogoAndDateContainer {
      position: absolute;
      right: 5px;
      top: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      &.eversheds {
        left: -130px;
        right: unset;
        .eperotoLogo {
          height: 220px;
          width: 280px;
          transform: rotate(-90deg);
        }
      }

      .eperotoLogo {
        width: 204px;
        height: 132px;
        margin-bottom: 11px;
        object-fit: contain;
      }
    }
    .currentDateContainer {
      .currentDateInput,
      .currentDate {
        text-align: center;
        border: 1px solid $lakersUniform;
        border-radius: 3px;
        width: 180px;
        padding-top: 1px;
        &.eversheds {
          text-align: right;
          margin-right: -7px;
          padding-right: 4px;
          &:hover {
            padding-right: 3px;
          }
        }

        &:focus {
          outline: none;
        }
        &.currentDate {
          border: 0px solid white;
          padding-top: 2px;
          &:hover {
            border: 1px solid $lakersUniform;
            padding-top: 1px;
          }
        }
      }

      .noDate {
        color: $mondayGray;
      }
    }
  }
  .reportAddSectionContainer {
    margin-left: calc(#{$reportPaddingLeft - 15px});
    //margin-right: calc(#{$reportPaddingLeft - 15px});
    margin-top: 65px;
    margin-bottom: 30px;
    padding-top: 19px;
    padding-bottom: 17px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 50px;
    .reportAddSectionBackgroundLine {
      height: 2px;
      background-color: $ageanMidnight;
      width: 100%;
      position: relative;
      .reportAddSectionButtonContainer {
        width: 190px;
        background-color: white;
        position: absolute;
        top: -19px;
        left: calc(50% - 95px);
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }
    }
    .lowOpacityLayer {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      background-color: white;
      opacity: 0.8;
    }
  }
}
