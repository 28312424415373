.secondTrialOptionContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #f8f8f8;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  padding-bottom: 10px;
  width: 92%;
  margin-left: 40px;
  .dropdown,
  .inputNum,
  .inputdate,
  .inputNum-RO {
    margin-left: 15px !important;
  }

  .secTrialContainer {
    display: flex;
    //align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    margin-top: 18px;
    flex-wrap: wrap;
    margin-bottom: 12px;
    margin-right: 12px;
  }

  .secTrialObj {
    position: relative;
    width: 240px;
    margin-right: 30px;
  }

  .deleteContainer {
    position: absolute;
    right: 20px;
    top: 3px;
    cursor: pointer;
    img {
      height: 17px;
    }
  }
}

.probSecondTrialstep1 {
  .input-label-text-p {
    letter-spacing: -0em;
  }
}

#courtDetailsComp {
  margin-bottom: 200px;
}

.customPartyFormatInput {
  margin-left: 80px !important;
  margin-top: -28px !important;
}

.dateInputHOCContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  .dateInputContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    .dateInputDescription {
      max-width: 300px;
      min-width: 130px;
    }
  }
}
